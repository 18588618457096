<template>
	<div class="footermain">
		<div class="f_div" :class="{selectf_div:active=='/'}" @click="toLinkUrl('/')">
			<img src="/static/imgs/shouye.png" v-if="active!='/'">
			<img src="/static/imgs/shouye_select.png" v-else>
			<span>首页</span>
		</div>
		<div class="f_div" :class="{selectf_div:active=='/room'}" @click="toLinkUrl('/room')">
		
			<img src="/static/imgs/room.png" v-if="active!='/room'">
			<img src="/static/imgs/room_select.png" v-else>
			<span>房间</span>
		</div>
		<div class="f_div" :class="{selectf_div:active=='/mine'}" @click="toLinkUrl('/mine')">
		<img src="/static/imgs/user.png" v-if="active!='/mine'">
			<img src="/static/imgs/user_select.png" v-else>
			<span>我的</span>
		</div>
     
	</div>
</template>

<script>
export default {
   name: 'footermain',
	props:{
		active:""
   	},
   data(){
      return{
        
      }
      
   },
	methods:{
		toLinkUrl(url){
			this.$router.push(url)
		}
	},
   watch:{
     
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.footermain{
		width: 100%;
		height: 98px;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: fixed;
		left: 0;
		bottom: 0;

	}
	.f_div{
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 24px;
		color: #999999;
		line-height: 31px;
	}
	.f_div img{
		width: 40px;
		height: 40px;
	}
	.selectf_div{
		color: #4E89FF;
	}
</style>
